import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import { Callout, CalloutStatus, Icon, Colors } from '@class101/ui';
import { imageSrc } from './interface';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "callout"
    }}>{`Callout`}</h1>
    <p>{`한 화면에서 가장 중요한 주의사항이 필요할 때 최소로 쓸 것을 권장합니다.`}</p>
    <h2 {...{
      "id": "props"
    }}>{`Props`}</h2>
    <Props of={Callout} mdxType="Props" />
    <h2 {...{
      "id": "basic-usage"
    }}>{`Basic usage`}</h2>
    <Playground __position={1} __code={'<Callout title=\"공지사항\">\n  설명문구가 들어가는 자리입니다. 설명문구가 들어가는 자리입니다. 설명문구가\n  들어가는 자리입니다\n</Callout>\n<br />\n<Callout title=\"공지사항\" />'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Callout,
      CalloutStatus,
      Icon,
      Colors,
      imageSrc,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Callout title="공지사항" mdxType="Callout">
    설명문구가 들어가는 자리입니다. 설명문구가 들어가는 자리입니다. 설명문구가 들어가는 자리입니다
  </Callout>
  <br />
  <Callout title="공지사항" mdxType="Callout" />
    </Playground>
    <h2 {...{
      "id": "status"
    }}>{`Status`}</h2>
    <Playground __position={2} __code={'Normal/info\n<Callout title=\"공지사항\" status={CalloutStatus.DEFAULT}>\n  설명문구가 들어가는 자리입니다. 설명문구가 들어가는 자리입니다. 설명문구가\n  들어가는 자리입니다\n</Callout>\n<br />\nSuggest\n<Callout title=\"서비스 이용 방법\" status={CalloutStatus.SUGGEST}>\n  설명문구가 들어가는 자리입니다. 설명문구가 들어가는 자리입니다. 설명문구가\n  들어가는 자리입니다\n</Callout>\n<br />\nWarning\n<Callout\n  title=\"주의사항, 아직 일어나지 않은 사고\"\n  status={CalloutStatus.WARNING}\n>\n  설명문구가 들어가는 자리입니다. 설명문구가 들어가는 자리입니다. 설명문구가\n  들어가는 자리입니다\n</Callout>\n<br />\nDanger\n<Callout title=\"금지사항, 오류, 사고\" status={CalloutStatus.DANGER}>\n  설명문구가 들어가는 자리입니다. 설명문구가 들어가는 자리입니다. 설명문구가\n  들어가는 자리입니다\n</Callout>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Callout,
      CalloutStatus,
      Icon,
      Colors,
      imageSrc,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  Normal/info
  <Callout title="공지사항" status={CalloutStatus.DEFAULT} mdxType="Callout">
    설명문구가 들어가는 자리입니다. 설명문구가 들어가는 자리입니다. 설명문구가 들어가는 자리입니다
  </Callout>
  <br />
  Suggest
  <Callout title="서비스 이용 방법" status={CalloutStatus.SUGGEST} mdxType="Callout">
    설명문구가 들어가는 자리입니다. 설명문구가 들어가는 자리입니다. 설명문구가 들어가는 자리입니다
  </Callout>
  <br />
  Warning
  <Callout title="주의사항, 아직 일어나지 않은 사고" status={CalloutStatus.WARNING} mdxType="Callout">
    설명문구가 들어가는 자리입니다. 설명문구가 들어가는 자리입니다. 설명문구가 들어가는 자리입니다
  </Callout>
  <br />
  Danger
  <Callout title="금지사항, 오류, 사고" status={CalloutStatus.DANGER} mdxType="Callout">
    설명문구가 들어가는 자리입니다. 설명문구가 들어가는 자리입니다. 설명문구가 들어가는 자리입니다
  </Callout>
    </Playground>
    <h2 {...{
      "id": "custom-icon"
    }}>{`Custom Icon`}</h2>
    <Playground __position={3} __code={'<Callout title=\"공지사항\" icon={<Icon.Project fillColor={Colors.red600} />}>\n  설명문구가 들어가는 자리입니다. 설명문구가 들어가는 자리입니다. 설명문구가\n  들어가는 자리입니다\n</Callout>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Callout,
      CalloutStatus,
      Icon,
      Colors,
      imageSrc,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Callout title="공지사항" icon={<Icon.Project fillColor={Colors.red600} />} mdxType="Callout">
    설명문구가 들어가는 자리입니다. 설명문구가 들어가는 자리입니다. 설명문구가 들어가는 자리입니다
  </Callout>
    </Playground>
    <h2 {...{
      "id": "action"
    }}>{`Action`}</h2>
    <p>{`Callout의 내용과 관련된 액션을 처리하는 버튼입니다.`}</p>
    <blockquote>
      <p parentName="blockquote">{`폼과 관련이 없도록 사용해주세요. (submit x)`}</p>
    </blockquote>
    <pre><code parentName="pre" {...{
        "className": "language-ts"
      }}>{`interface ActionProps {
  content: string;
  leftIcon?: ReactNode;
  rightIcon?: ReactNode;
  loading?: boolean;
  to?: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement> | React.MouseEvent<HTMLAnchorElement>) => void;
  disabled?: boolean;
  className?: string;
}
`}</code></pre>
    <Playground __position={4} __code={'<Callout\n  title=\"공지사항\"\n  icon={<Icon.Project fillColor={Colors.red600} />}\n  action={{ content: \'액션 버튼\', onClick: () => window.alert(\'Clicked\') }}\n>\n  설명문구가 들어가는 자리입니다. 설명문구가 들어가는 자리입니다. 설명문구가\n  들어가는 자리입니다\n</Callout>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Callout,
      CalloutStatus,
      Icon,
      Colors,
      imageSrc,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Callout title="공지사항" icon={<Icon.Project fillColor={Colors.red600} />} action={{
        content: '액션 버튼',
        onClick: () => window.alert('Clicked')
      }} mdxType="Callout">
    설명문구가 들어가는 자리입니다. 설명문구가 들어가는 자리입니다. 설명문구가 들어가는 자리입니다
  </Callout>
    </Playground>
    <h2 {...{
      "id": "content"
    }}>{`Content`}</h2>
    <Playground __position={5} __code={'<Callout title=\"공지사항\">\n  <p>\n    설명문구가 들어가는 자리입니다. 설명문구가 들어가는 자리입니다. 설명문구가\n    들어가는 자리입니다. 설명문구가 들어가는 자리입니다. 설명문구가 들어가는\n    자리입니다. 설명문구가 들어가는 자리입니다. 설명문구가 들어가는\n    자리입니다. 설명문구가 들어가는 자리입니다. 설명문구가 들어가는 자리입니다\n  </p>\n  <p>\n    설명문구가 들어가는 자리입니다. 설명문구가 들어가는 자리입니다. 설명문구가\n    들어가는 자리입니다. 설명문구가 들어가는 자리입니다. 설명문구가 들어가는\n    자리입니다. 설명문구가 들어가는 자리입니다. 설명문구가 들어가는\n    자리입니다. 설명문구가 들어가는 자리입니다. 설명문구가 들어가는 자리입니다\n  </p>\n  <p>\n    설명문구가 들어가는 자리입니다. 설명문구가 들어가는 자리입니다. 설명문구가\n    들어가는 자리입니다\n  </p>\n  <div style={{ borderRadius: \'3px\', overflow: \'hidden\', height: \'200px\' }}>\n    <img\n      src={imageSrc}\n      style={{ display: \'block\', objectFit: \'cover\', height: \'100%\' }}\n    />\n  </div>\n</Callout>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Callout,
      CalloutStatus,
      Icon,
      Colors,
      imageSrc,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Callout title="공지사항" mdxType="Callout">
    <p>
      설명문구가 들어가는 자리입니다. 설명문구가 들어가는 자리입니다. 설명문구가 들어가는 자리입니다. 설명문구가
      들어가는 자리입니다. 설명문구가 들어가는 자리입니다. 설명문구가 들어가는 자리입니다. 설명문구가 들어가는
      자리입니다. 설명문구가 들어가는 자리입니다. 설명문구가 들어가는 자리입니다
    </p>
    <p>
      설명문구가 들어가는 자리입니다. 설명문구가 들어가는 자리입니다. 설명문구가 들어가는 자리입니다. 설명문구가
      들어가는 자리입니다. 설명문구가 들어가는 자리입니다. 설명문구가 들어가는 자리입니다. 설명문구가 들어가는
      자리입니다. 설명문구가 들어가는 자리입니다. 설명문구가 들어가는 자리입니다
    </p>
    <p>설명문구가 들어가는 자리입니다. 설명문구가 들어가는 자리입니다. 설명문구가 들어가는 자리입니다</p>
    <div style={{
          borderRadius: '3px',
          overflow: 'hidden',
          height: '200px'
        }}>
      <img src={imageSrc} style={{
            display: 'block',
            objectFit: 'cover',
            height: '100%'
          }} />
    </div>
  </Callout>
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      